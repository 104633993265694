<template>
  <div class="">
    <h2>อัพโหลดราศี</h2>
    <b-row class="mt-2">
      <b-col sm="12">
        <b-overlay :show="loading" no-wrap />
        <b-overlay :show="!$store.getters['AuthStore/_isCanAccessSomeFeature'] || _isMarketing" rounded="sm">
          <template v-slot:overlay>
            <div class="d-flex align-items-center flex-column">
              <feather-icon icon="LockIcon" size="40" class="mb-50 text-danger" />
              <p v-if="_isMarketing" class="m-0 text-danger">ไม่สามารถเข้าถึงได้</p>
              <p v-else class="m-0 text-danger">ไม่สามารถเข้าถึงได้ เนื่องจากถูกระงับการใช้งาน</p>
            </div>
          </template>
          <FormUploadZodiac
            ref="refFormUploadZodiac"
            :default-form-zodiacs="defaultFormZodiacs"
            @onSave="onSaveZodiacs"
            @onCancel="onCancelForm"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <h2 class="mb-2">ราศีทั้งหมด</h2>
    <tab-translations-widget
      v-show="!loading && zodiacs.length > 0"
      ref="refTabTranslations"
      disable-fetch
      @onChange="onTabTranslationsChange"
    />
    <br />
    <b-row>
      <b-col sm="12" v-for="(item, index) in zodiacs" :key="item.id">
        <b-card>
          <b-overlay :show="loading" no-wrap />
          <b-alert variant="danger" :show="item.approved_status === 'rejected'" class="mb-1">
            <div class="alert-body">
              <feather-icon icon="InfoIcon" class="mr-50" />
              {{ item.admin_note }}
            </div>
          </b-alert>
          <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
            <b-card-title class="mb-50 mb-sm-1">
              <b-badge
                v-if="_isAdmin || _isMarketing"
                :variant="item.user.role_data_preview === 'admin' ? 'warning' : 'primary'"
                class="mr-50 text-uppercase"
              >
                {{ gFormatRolePreview(item.user.role_data_preview) }}
              </b-badge>
              {{ item.name }}
              <br />
              <small>วันที่สร้าง {{ gFormatDate(item.created_at) }}</small>
              <span class="text-muted"> | </span>
              <small>อัปเดตล่าสุด {{ gFormatDate(item.update_at) }}</small>
              <small v-if="_isAdmin || _isMarketing" class="d-block mt-50">
                ผู้สร้าง {{ item.user.shop_name || '' }} ({{ item.user.email }})
              </small>
            </b-card-title>
            <div class="mb-50 mb-sm-0">
              สถานะรีวิว
              <b-badge :variant="statusFormatObj[item.approved_status].variant">
                {{ statusFormatObj[item.approved_status].label }}
              </b-badge>
              <!-- {{ item.approved_status }} -->
            </div>
          </div>
          <b-row class="mb-2" cols="1" cols-sm="2">
            <b-col>
              <b-card-text class="mb-0">
                รูปราศีทั้งหมด
              </b-card-text>
              <b-card-text class="ml-2">{{ item.count_image }} รูป</b-card-text>
            </b-col>
            <b-col class="mt-1 mt-sm-0">
              <b-card-text class="mb-0"> ขนาดรูปภาพ <small class="text-muted">( width x height )</small> </b-card-text>
              <b-card-text class="ml-2">{{ dimensionZodiacImage(item) }}</b-card-text>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-for="(image, indexImage) in item.topic_image_list_datas" :key="indexImage" sm="12" md="4" lg="3">
              <div class="container-zodiac-item">
                <img :src="image.image_preview" class="ct-cursor-zoom" @click="gZoomImage" />
              </div>
              <p class="text-center">{{ image.name_translations[keyTranslation] || 'พบข้อผิดพลาด' }}</p>
              <!-- <pre>{{ JSON.stringify(image, null, 2) }}</pre> -->
            </b-col>
          </b-row>
          <div v-if="cShowBtnEditOrDeleteZodiac(item)" class="btn-group-zodiac-item">
            <b-button
              v-if="!_isMarketing"
              variant="primary"
              :disabled="!$store.getters['AuthStore/_isCanAccessSomeFeature']"
              @click="edit(item)"
            >
              แก้ไข
            </b-button>

            <b-button
              variant="danger"
              :disabled="!$store.getters['AuthStore/_isCanAccessSomeFeature']"
              @click="clickDelete(index, item)"
            >
              ลบ
            </b-button>
          </div>

          <!-- <pre>{{ JSON.stringify(item, null, 2) }}</pre> -->
        </b-card>
      </b-col>
    </b-row>
    <!-- <pre>{{ JSON.stringify(zodiacs, null, 2) }}</pre> -->
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapState, mapGetters } from 'vuex'
import FormUploadZodiac from './components/FormUploadZodiac.vue'

export default {
  components: { FormUploadZodiac },
  data() {
    return {
      zodiacs: [],
      defaultFormZodiacs: null,
      mockStores: [],
      loading: false,
      keyTranslation: 'th',

      statusFormatObj: {
        1: {
          label: '1',
          variant: 'danger',
        },
        '': {
          label: '',
          variant: '',
        },
        approved: {
          label: 'อนุมัติ',
          variant: 'success',
        },
        rejected: {
          label: 'ไม่อนุมัติ',
          variant: 'danger',
        },
        reject: {
          label: 'ไม่อนุมัติ',
          variant: 'danger',
        },
        pending: {
          label: 'รอนุมัติ',
          variant: 'secondary',
        },
        checking: {
          label: 'กำลังตรวจสอบ',
          variant: 'warning',
        },
        approve: {
          label: 'อนุมัติ',
          variant: 'success',
        },
      },
    }
  },
  computed: {
    ...mapGetters('AuthStore', ['_isAdmin', '_isMarketing', '_isPartner']),
    ...mapState('app-mobile-public-configs', ['langsSupport']),

    // eslint-disable-next-line no-underscore-dangle
    _column() {
      return [
        {
          label: 'ชื่อ ราศรี',
          field: 'name',
        },
        {
          label: 'จำนวนรูป',
          field: 'count_image',
          type: 'number',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ]
    },
    cDefaultTranslationObj() {
      const defaultKeysTranslationObj = {}

      this.langsSupport.forEach(v => {
        defaultKeysTranslationObj[v.local_code] = ''
      })
      return defaultKeysTranslationObj
    },
    cShowBtnEditOrDeleteZodiac() {
      return currentItem => {
        // eslint-disable-next-line no-underscore-dangle
        if (this._isMarketing && currentItem.user.role_data_preview === 'admin') return false

        return currentItem.approved_status !== 'checking'
      }
    },
  },
  async created() {
    this.queryItems()
  },
  methods: {
    async queryItems() {
      this.loading = true
      const res = await this.api.get('/api/admin/admin-zodiac-image-data')
      this.zodiacs = [...res]
      // this.zodiacs = [...res].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      this.defaultFormZodiacs = null
      this.loading = false
    },
    async onSaveZodiacs(data) {
      const checkConfirm = await this.gCheckConfirmV1(data.id ? 'แก้ไขหมวดหมู่ราศี' : 'เพิ่มหมวดหมู่ราศี')
      if (!checkConfirm) return

      this.loading = true

      const resCreateMain = await this.api.post('/api/admin/admin-zodiac-image-data', {
        title: data.title,
        data_type: 'main',
        id: data.id ? data.id : '',
      })
      // console.log('upload zodiacs', resCreateMain)

      if (resCreateMain.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resCreateMain.message, 'danger')
        this.loading = false
        this.$refs.refFormUploadZodiac.clearForm()

        await this.queryItems()
        return
      }

      if (!resCreateMain.topic_image_data_id) return

      await this.createOrUpdateZodiacs(data.zodiacs, resCreateMain.topic_image_data_id)
      // await new Promise(resolve => setTimeout(resolve, 3000))
      await this.queryItems()

      this.$refs.refFormUploadZodiac.clearForm()
      this.loading = false
    },
    // eslint-disable-next-line camelcase
    async createOrUpdateZodiacs(zodiacs, topic_image_data_id) {
      for (let index = 0; index < zodiacs.length; index += 1) {
        const item = zodiacs[index]
        item.details.name = `${item.details.name_translations.th}`
        const form = new FormData()
        const newFile = item.file
        if (newFile) {
          newFile.id = item.id ? item.id : item.localId
        }
        form.append('file', newFile)
        form.append('details', Buffer.from(JSON.stringify(item.details)).toString('base64'))
        form.append('id', item.id ? item.id : '')
        form.append('topic_image_data_id', topic_image_data_id)
        form.append('data_type', 'zodiac')
        // eslint-disable-next-line no-await-in-loop
        await this.api.post('/api/admin/admin-zodiac-image-data', form)
      }

      return true
    },

    edit(item) {
      // console.log('edit', item)
      if (item.topic_image_list_datas.length < 0) return
      const zodiacs = item.topic_image_list_datas.map(val => {
        const jsonDataDetails = JSON.parse(val.json_data)
        const obj = {
          id: val.id,
          details: {
            ...jsonDataDetails,
            name_translations: {
              th: jsonDataDetails.name,
              ...val?.name_translations,
            },
          },
          previewUrl: val.image_preview,
        }
        return obj
      })
      const dataObj = {
        title: item.name,
        zodiacs,
        id: item.id,
      }

      // console.log('dataObj', dataObj)
      this.defaultFormZodiacs = JSON.parse(JSON.stringify(dataObj))
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    onCancelForm(id) {
      this.defaultFormZodiacs = null
      if (id) {
        this.queryItems()
      }
    },
    dimensionZodiacImage(item) {
      const firstData = item.topic_image_list_datas[0]
      if (!firstData) return 'ข้อมูลผิดพลาด'

      if (firstData.json_data) {
        const { naturalHeight, naturalWidth } = JSON.parse(firstData.json_data)
        return `${naturalWidth} x ${naturalHeight}`
      }

      return 'ไม่พบข้อมูล'
    },
    async clickDelete(index, imageInfo) {
      // console.log(imageInfo)
      if (imageInfo?.id) {
        let url = 'api/partner/manage-images/image-data/destroy'

        // eslint-disable-next-line no-underscore-dangle
        if (this._isAdmin || this._isMarketing) {
          url = '/api/admin/admin-topic-images'
        }

        // const resp = await this.api.deleteV2(`/api/admin/admin-topic-images/${imageInfo.id}`, this) //old
        const resp = await this.api.deleteV2(`${url}/${imageInfo.id}`, this)
        // console.log('clickDelete', resp)
        if (resp?.message) {
          this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
          await this.queryItems()
          return
        }
        if (!resp) return
      }
      this.zodiacs.splice(index, 1)
    },
    onTabTranslationsChange(values) {
      this.keyTranslation = values.local_code
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-good-table.scss';
.container-zodiac-item {
  width: 100%;
  height: 200px;
  background-color: #47526f;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0.5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}

.btn-group-zodiac-item {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
