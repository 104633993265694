<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <b-card>
      <div class="container-zodiac-upload">
        <p>ลากวางหรือคลิกเพื่ออัพโหลดราศี</p>
        <input
          ref="refInputUploadZodiacs"
          type="file"
          multiple
          accept="image/jpeg, image/png"
          @input="uploadZodiacs($event)"
        />
      </div>
      <div v-show="zodiacs.length > 0" class="my-1">
        <b-form-input v-model="title" placeholder="ระบุชื่อหมวดหมู่ราศี" />
        <hr />
        <tab-translations-widget ref="refTabTranslations" @onChange="onTabTranslationsChange" />
      </div>
      <div v-if="zodiacs.length > 0">
        <b-row>
          <b-col v-for="zodiac in zodiacs" :key="zodiac.localId" cols="12" sm="6" md="4" lg="3">
            <div class="container-zodiac-preview" :class="validateDimensionZodiac(zodiac)">
              <div class="preview">
                <img :src="zodiac.previewUrl" alt="zodiac preview" class="ct-cursor-zoom" @click="gZoomImage" />
              </div>
              <div class="details">
                <b-form-input
                  v-model="zodiac.details.name_translations[keyTranslation]"
                  placeholder="ระบุชื่อราศีของรูปนี้"
                />
                <small v-if="keyTranslation === 'th'" class="text-danger"> *จำเป็นต้องระบุ</small>
                <small v-else> (ถ้าไม่ระบุ จะแปลให้โดยอัตโนมัติ)</small>
                <div class="mt-1">
                  <h6>ขนาดรูป</h6>
                  <ul>
                    <li>{{ zodiac.details.naturalWidth }} <small class="text-muted">width</small></li>
                    <li>{{ zodiac.details.naturalHeight }} <small class="text-muted">height</small></li>
                  </ul>
                </div>
              </div>
              <b-button
                v-if="zodiacs.length > 1"
                variant="danger"
                size="sm"
                class="btn-delete"
                @click="removeZodiac(zodiac)"
              >
                ลบ
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div class="btn-group-footer-zodiacs">
          <b-button variant="secondary" @click="onClickCancel">
            ยกเลิก
          </b-button>
          <b-button variant="primary" @click="onClickSave">
            บันทึก
          </b-button>
        </div>
      </div>
    </b-card>
    <!-- <pre>{{ JSON.stringify(id, null, 2) }}</pre> -->
    <!-- <pre>{{ JSON.stringify(zodiacs, null, 2) }}</pre> -->
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    defaultFormZodiacs: Object,
  },

  data() {
    return {
      zodiacs: [],
      title: '',
      id: null,
      keyTranslation: 'th',
    }
  },
  computed: {
    ...mapState('app-mobile-public-configs', ['langsSupport']),
    cDefaultTranslationObj() {
      const defaultKeysTranslationObj = {}

      this.langsSupport.forEach(v => {
        defaultKeysTranslationObj[v.local_code] = ''
      })
      return defaultKeysTranslationObj
    },
  },
  methods: {
    checkDimensionImage(imgUrl) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = e => {
          // const { naturalWidth, naturalHeight } = e.path[0] // e.path บางบราวเซอร์ไม่มีทำให้แตก
          const { naturalWidth, naturalHeight } = img
          resolve({ naturalHeight, naturalWidth })
        }
        img.onerror = reject

        img.src = imgUrl
      })
    },
    async uploadZodiacs(event) {
      const filesObj = event.target.files
      Object.keys(filesObj).map(async val => {
        const file = filesObj[val]
        const fileSize = Number(file.size / (1024 * 1024)).toFixed(2)
        if (fileSize > 10) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'ขนาดรูปผิดพลาด',
              icon: 'BellIcon',
              text: 'ขนาดรูปที่ต้องการต้องไม่เกิน 10 mb',
              variant: 'danger',
            },
          })
          return
        }

        const previewUrl = URL.createObjectURL(file)

        try {
          const dimension = await this.checkDimensionImage(previewUrl)
          const localId = uuidv4()

          const newFile = {
            localId,
            file,
            previewUrl,
            details: {
              naturalHeight: dimension.naturalHeight,
              naturalWidth: dimension.naturalWidth,
              name: '',
              name_translations: {
                ...this.cDefaultTranslationObj,
              },
            },
          }
          this.zodiacs = [...this.zodiacs, newFile]
        } catch (e) {
          //
        }
      })
      this.$refs.refInputUploadZodiacs.value = null
    },
    async removeZodiac(zodiac) {
      if (zodiac.id) {
        const resp = await this.api.deleteV2(`/api/admin/admin-topic-image-lists/${zodiac.id}`, this)
        if (!resp) return
        if (resp.message) {
          this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
          return
        }
        this.zodiacs = this.zodiacs.filter(item => item.id !== zodiac.id)
      } else {
        this.zodiacs = this.zodiacs.filter(item => item.localId !== zodiac.localId)
      }
    },
    validateDimensionZodiac(zodiac) {
      if (this.zodiacs.length <= 1) return ''
      const fistZodiac = this.zodiacs[0]
      const { naturalHeight, naturalWidth } = zodiac.details

      if (fistZodiac.details.naturalHeight !== naturalHeight && fistZodiac.details.naturalWidth !== naturalWidth) {
        return 'error'
      }

      return ''
    },
    onClickSave() {
      if (this.zodiacs.length > 1) {
        const firstZodiac = this.zodiacs[0]
        const { naturalHeight, naturalWidth } = firstZodiac.details
        const inValidDimension = this.zodiacs.some(
          val => val.details.naturalHeight !== naturalHeight && val.details.naturalWidth !== naturalWidth,
        )

        if (inValidDimension) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'โปรดเช็คขนาดรูปอีกครั้ง',
              icon: 'BellIcon',
              text: 'ขนาดรูปต้องเท่ากันทั้งหมด โดยอ้างอิงจากรูปแรกสุดเท่านั้น',
              variant: 'danger',
            },
          })
          return
        }
      }

      const inValidNameRequire = this.zodiacs.some(val => `${val.details.name_translations.th}`.trim() === '')
      if (inValidNameRequire) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'โปรดเช็ครายละเอียดรูปอีกครั้ง',
            icon: 'BellIcon',
            text: 'รูปราศรีจำเป็นจะต้องระบุชื่อราศรีของรูปนั้นๆ ของหมวดหมู่ภาษาไทย',
            variant: 'danger',
          },
        })
        return
      }

      if (!this.title) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'โปรดเช็ครายละเอียดอีกครั้ง',
            icon: 'BellIcon',
            text: 'โปรดระบุชื่อหมวดหมู่ราศี',
            variant: 'danger',
          },
        })
        return
      }

      const data = {
        zodiacs: this.zodiacs,
        title: this.title,
        id: this.id,
      }
      this.$emit('onSave', data)
    },
    onClickCancel() {
      this.$emit('onCancel', this.id)
      this.title = ''
      this.zodiacs = []
      this.id = null
    },
    clearForm() {
      this.zodiacs = []
      this.title = ''
      this.id = null
    },
    onTabTranslationsChange(values) {
      this.keyTranslation = values.local_code
    },
  },
  watch: {
    // defaultFormZodiacs(newValue) {
    //   console.log('defaultFormZodiacs', newValue)
    //   if (newValue) {
    //     this.zodiacs = newValue.zodiacs.map(o => ({ ...o }))
    //     this.title = newValue.title
    //     this.id = newValue.id
    //   }
    // },
    defaultFormZodiacs: {
      deep: false,
      handler(newValue) {
        if (newValue) {
          this.zodiacs = [...newValue.zodiacs]
          this.title = newValue.title
          this.id = newValue.id
        } else {
          this.zodiacs = []
          this.title = ''
          this.id = null
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.container-zodiac-upload {
  width: 100%;
  height: 100px;
  background-color: whitesmoke;
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgb(187, 185, 185);
  cursor: pointer;
  p {
    margin: 0;
  }
  input {
    width: 100%;
    height: 100%;
    border: 1px solid red;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}
.container-zodiac-preview {
  width: 100%;
  margin-bottom: 1rem;
  border: 2px solid #000000;
  border-radius: 0.5rem;
  display: flex;
  position: relative;
  flex-direction: column;
  &.error {
    border-color: red;
  }
  .preview {
    width: 100%;
    background-color: #47526f;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
      object-fit: scale-down;
      width: 100%;
      max-height: 150px;
    }
  }
  .details {
    padding: 1rem;
    flex: 1;
  }
  .btn-delete {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
.btn-group-footer-zodiacs {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
